import React from "react";
import { GoogleOAuthProvider, GoogleLogin} from '@react-oauth/google';

export default function SignUp() {

    return (
        <div className="flex py-5 md:items-center justify-center h-screen">
            <div className="bg-white px-4 py-8 md:w-1/4 border border-gray-200 rounded-lg shadow-lg flex flex-col items-center">
                <h1 className="text-lg py-3">Create your account</h1>
                <div className="w-full">
                    <GoogleOAuthProvider clientId="1028057814503-6cnp0h2cqfg5ss6102apjdto4q5jpguv.apps.googleusercontent.com">
                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            console.log(credentialResponse);
                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
                    </GoogleOAuthProvider>
                </div>
                
            </div>
        </div>
    )
}