import './App.css';
import background from './images/background2.png';
import SignUp from './components/SignUp';

function App() {
  return (
    <div className="App">
      <img className="absolute w-full h-full object-cover -z-10" src={background} alt='background'/>
      <SignUp />
    </div>
  );
}

export default App;
